import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper'
import CryptoCard from '../../../components/CryptoCard'
import { imageSlider } from '../../../constant'
//import 'swiper/swiper-bundle.min.css'
//import 'swiper/swiper.min.css'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

function ImgSlide() {
  return (
    <section className="img-slide">
      <div className="container">
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          clickable={true}
          loop={true}
          modules={[Pagination, Autoplay]}
          autoplay={{
            delay: 8000,
            disableOnInteraction: false
          }}
          pagination={{
            clickable: true
          }}>
          {imageSlider.map((item, index) => {
            return (
              <>
                <SwiperSlide key={index}>
                  {item?.url ? (
                    <a href={item?.url} target='_blank'>
                      <img src={item?.image} alt={item?.altText} />
                    </a>
                  ) : (
                    <img src={item?.image} alt={item?.altText} />
                  )}
                </SwiperSlide>
              </>
            )
          })}
        </Swiper>
      </div>
    </section>
  )
}

export default ImgSlide
