import React from 'react'
import Button from '../../../components/button'
import TradingTable from '../../../components/Table'
import {  TableData } from '../../../constant'
import Arrow from '../../../assets/images/arrow-right.svg'

function DynamicTable({currencyData,load,error}) {
  return (
    <section className="dynamic-table">
      <div className="container">
        <h2 className="h2heading">Trending Pairs</h2>
        <div className="trading-table">
          <TradingTable data={currencyData} header={TableData} loader={load} error={error} />
        </div>
        {/* <Button url="#" title="View more prices" bg="#D21C7C" color="#fff" icon={Arrow} /> */}
      </div>
    </section>
  )
}

export default DynamicTable
