import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper'
import CryptoCard from '../../../components/CryptoCard'
//import 'swiper/swiper-bundle.min.css'
//import 'swiper/swiper.min.css'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination'

function TredingSlide({currencyData,load,error}) {
  return (
    <section className="trading-slide">
      <Swiper
        spaceBetween={50}
        slidesPerView={4}
        clickable={true}
        loop={true}
        modules={[Pagination, Autoplay]}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false
        }}
        pagination={{
          clickable: true
        }}
        breakpoints={{
          320: {
            slidesPerView: 1
          },
          768: {
            slidesPerView: 2
          },
          1023: {
            slidesPerView: 3,
            spaceBetween: 28,
            resistanceRatio: 0.85
          },
          1366: {
            slidesPerView: 4,
            spaceBetween: 32,
            resistanceRatio: 0
          }
        }}>
        {load ? (
          <p style={{ color: '#FFF' }}>Loading....</p>
        ) : error && !load ? (
          <p style={{ color: '#FFF' }}>{error}</p>
        ) : (
          currencyData !== undefined &&
          currencyData?.length &&
          currencyData?.map((item, index) => {
            return (
              <>
                <SwiperSlide key={index}>
                  <CryptoCard item={item} loader={load} error={error} />
                </SwiperSlide>
              </>
            )
          })
        )}
      </Swiper>
    </section>
  )
}

export default TredingSlide
